if ($('.page-phototheque').length > 0) {

    th_overlay.addCallbackClose(function (overlayId) {
        // on veut dans ce cas fermer tous les overlay ouvertes de la photothèque
        if (overlayId.indexOf('phototheque')) {
            $('[id^="overlay-photo"].open').each(function () {
                th_overlay.close($(this).attr('id'));
            })
        }
    });
}


var init_mediatheque = function () {
    $('.overlay-phototheque form').on('submit', function (e) {
        var _this = $(this);
        e.preventDefault();
        $.post(
            $(this).attr('action'),
            $(this).serialize(),
            function (response) {
                if (response) {
                    if (response.success) {
                        _this.find('fieldset > div:not(.frm_submit)').addClass('hide');
                        $('.btn', _this).replaceWith('<a class="btn btn-primary" target="_blank" href="' + response.url + '" download >' + $(_this).data('trad-success') + '</a>');
                    } else {
                        alert($(_this).data('trad-badpwd'));
                    }
                }
            },
            'json'
        );
    });
}
init_mediatheque();

var mediapageManager = {
    lastRequestId: null,
    form_selector: '.media-select',
    form_download: '.overlay-phototheque form',
    carouselselected: null,

    colcadeGrid: ".js-listing-grid",
    colcade: null,

    init: function () {
        mediapageManager.formEvent();
        mediapageManager.paginationEvent();
        mediapageManager.btnReset();
        mediapageManager.btnSubmit();
        mediapageManager.initCols();
    },

    initCols: function () {
        if (document.querySelector(mediapageManager.colcadeGrid)) {
            mediapageManager.colcade = new Colcade(mediapageManager.colcadeGrid, {
                columns: '.grid-col',
                items: '.grid-item'
            });
        }
    },

    btnReset: function () {
        var btnReset = document.querySelector(mediapageManager.form_selector + " .remove");
        var link = window.location.href;

        if (link.match(/\?.*/)) {
            link = link.replace(/\?.*/, '');
        }

        if (btnReset) {
            btnReset.setAttribute("href", link);
            btnReset.addEventListener("click", function () {
                window.location.href = btnReset.getAttribute("href");
            })
        }
    },

    btnSubmit: function () {
        var btnSubmitForm = document.querySelector(mediapageManager.form_selector + " button[type='submit']");
        if (btnSubmitForm) {
            btnSubmitForm.addEventListener("click", function () {
                th_overlay.close("overlay-filtres");
                $(mediapageManager.form_selector).submit();
            })
        }
    },

    scrollToTop: function () {
        $(window).scrollTop(0);
        $('#phototheque').height($('#phototheque').height()).html('<p class="aligncenter">Chargement en cours ...</p>');
    },

    formEvent: function () {
        $('select#cat', mediapageManager.form_selector).on('change', function () {
            mediapageManager.selectedFacetteAdd(this.value, this.getAttribute("name"), document.querySelector("select[name='" + this.getAttribute("name") + "'] option[value='" + this.value + "']").textContent);

            $(mediapageManager.form_selector).submit();
        });

        $(mediapageManager.form_selector).on('submit', function (e) {
            e.preventDefault();
            e.stopPropagation();

            var actionUrl = $('select#cat', this).val();
            if (typeof window.history != "undefined" && window.history.pushState) {
                window.history.pushState("", "", actionUrl);
            } else {
                document.location.href = actionUrl;
            }

            thisRequestid = new Date().getTime();
            mediapageManager.lastRequestId = thisRequestid;
            $.get(actionUrl, $(this).serialize(), function (data) {
                if (mediapageManager.lastRequestId == thisRequestid) {
                    mediapageManager.updateContent(data);
                    mediapageManager.updateLazy(data);
                }
            });
        });
    },

    selectedFacetteAdd: function (value, name, display_name) {
        if (mediapageManager.carouselselected) {
            var carousel = $(mediapageManager.carouselselected);

            if (value) {
                $(mediapageManager.carouselselected).slick('removeSlide', null, null, true);

                var item_html = '<div class="item"><button class="gtm-liste-filtre-suppr" aria-label="Supprimer le filtre' + display_name + '" type="button" data-facetteselectedvalue="' + value + '" data-facetteselectedname="' + name + '">' + display_name + '</button></div>';
                carousel.slick('slickAdd', item_html);

                mediapageManager.selectedFacetteInitRemove($('[data-facetteselectedvalue="' + value + '"]'));
            }
        }
    },

    selectedFacetteInitRemove: function ($elem) {
        if (mediapageManager.carouselselected) {
            $elem.on('click', function () {
                var input_name = $elem.data('facetteselectedname');

                $('[name="' + input_name + '"]', mediapageManager.form_selector).prop('selectedIndex', 0).selectric('refresh');

                // mise à jour du slider de valeurs séléctionnées
                mediapageManager.selectedFacetteRemove($elem);

                $(mediapageManager.form_selector).submit();
            })
        }
    },

    selectedFacettesRemove: function () {
        $('[data-facetteselectedvalue]').each(function () {
            mediapageManager.selectedFacetteInitRemove($(this));
        })
    },

    selectedFacetteRemove: function ($elem) {
        if (mediapageManager.carouselselected) {
            var carousel = $(mediapageManager.carouselselected);

            // index donné par slick, mais celui ci ne se met pas à jour quand on ajoute ou retire des éléments
            // var slick_index = $elem.parent().data('slick-index');

            // position réelle de l'élément dans le slider
            var slick_index = $('.item').index($elem.parent());

            carousel.slick('slickRemove', slick_index);
        }
    },

    updateLazy: function (data) {
        $('#phototheque .card-phototheque', data).each(function () {
            var lazyLoadInstance = new LazyLoad({
                elements_selector: ".lazy"
            });
        });
    },

    updateContent: function (data) {
        var indicPagination = $(data).find('.indic-pagination').html();
        var cards = $(data).find('.js-phototeque-cards').html();
        var overlays = $('#overlays > *', data);
        var nb_results = $(data).find('.resultats').html();

        mediapageManager.colcade.destroy();

        $('.js-phototeque-cards').html('').append(cards);
        mediapageManager.colcade = new Colcade(mediapageManager.colcadeGrid, {
            columns: '.grid-col',
            items: '.grid-item'
        });

        $('.resultats').html(nb_results);
        $('.indic-pagination').html(indicPagination)

        $('#overlays').html('').append(overlays);
        th_overlay.parse($('body'));
        var lazyLoadInstance = new LazyLoad({
            elements_selector: ".lazy"
        });

        $('#pagination').html('').append($('#pagination > *', data));
        mediapageManager.paginationEvent();

        init_mediatheque();
        th_overlay.init();
    },

    paginationEvent: function () {
        $('#pagination a').on('click', function (e) {
            e.preventDefault();

            mediapageManager.scrollToTop();

            var actionUrl = $(this).attr('href');
            if (typeof window.history != "undefined" && window.history.pushState) {
                window.history.pushState("", "", actionUrl);
            } else {
                document.location.href = actionUrl;
            }

            thisRequestid = new Date().getTime();
            mediapageManager.lastRequestId = thisRequestid;

            $.get(actionUrl, {}, function (data) {
                if (mediapageManager.lastRequestId == thisRequestid) {
                    mediapageManager.updateContent(data);
                    mediapageManager.updateLazy(data);
                }
            });
        });
    },
};

var find_page_mediatheque = document.querySelector('.page-phototheque');
if (find_page_mediatheque) {
    mediapageManager.init();
}

class blocExperiences {

    _selector = {
        parrent: '',
        element: '.element',
        active: '[data-index]',
    }

    splideOptions = {
        pagination: false,
        arrows: false,
        type: 'loop',
        focus: 'center'
    }

    splide = null;
    listnening = false;

    constructor(exp) {
        let cela = this;
        this._selector.parrent = exp;
        this.initSplide();

        this._selector.parrent.querySelectorAll(this._selector.element).forEach((e) => {
            e.addEventListener('mouseover', (event) => {
                this.hoverEvent(event.target);
            });
        });
    }

    initSplide(){
        let sliders = document.querySelectorAll('.js-slider-bloc-experiences');
        if (window.screen.width <= 980) {
            // Init des sliders
            sliders.forEach(slider => {
                this.splide = new Splide(slider, this.splideOptions).mount();
                this.listenSplide();
            });
        }

        window.addEventListener("resize", () => {
            if (window.screen.width <= 980) {
                sliders.forEach(slider => {
                    this.splide = new Splide(slider, this.splideOptions).mount();
                    this.listenSplide();
                });
            }
        });
    }

    listenSplide(){
        if (!this.listnening){
            this.listnening = true;

            this.splide.on('active', (slide) => {
                console.log(slide.slide);
                const newActive = slide.slide.getAttribute('data-index');
                this.changeActive(newActive);
            });
        }
    }

    hoverEvent(e){
        if (e.classList.contains('element')){
            const index = e.getAttribute('data-index');
            this.changeActive(index);
        }
    }

    changeActive(index){
        this._selector.parrent.querySelectorAll(this._selector.active).forEach((e) => {
            if (e.classList.contains('--active')){
                if (e.getAttribute('data-index') != index) {
                    e.classList.remove('--active');
                }
            }

            if (e.getAttribute('data-index') == index){
                e.classList.add('--active');
            }
        });
    }

}

(function () {
    let blocExperiencesArray = [];

    const blocExperiencesSelector = document.querySelectorAll('.js-bloc-experiences');
    blocExperiencesSelector.forEach(exp => {
        let newExperience = new blocExperiences(exp);
        blocExperiencesArray.push(newExperience);
    });

})();
class blocTrombinoscope {

    bloc = '.bloc-trombinoscope__element';
    overlay = '.overlay-trombinoscope';

    constructor() {
        var cela = this;
        $(this.bloc).on('click', function(e){
            e.preventDefault();
            cela.loadOverlayContent(this);
        })
    }

    loadOverlayContent(cela){
        $(this.overlay + " .loader").removeClass('hide');

        console.log(this);

        var html = $(cela).html();

        if (html){
            $(this.overlay + " .loader").addClass('hide');
            $(this.overlay + ' .scroller').html(html);

            var lazyLoadOverlay = new LazyLoad({
                elements_selector: ".lazy"
            });
        }
    }

}

var toto  = new blocTrombinoscope;
class strateAlerte {

    _selector = {
        parrent: '.js-strate-alerte',
        close: '.btn-close',
    }

    _elem = {
        alerte: null,
        close: null,
    }

    constructor(alerte) {
        this._elem.alerte = alerte;
        this._elem.close = this._elem.alerte.querySelector(this._selector.close)
        this.showAlerte();
        this.close();
    }

    showAlerte(){
        //On attend le chargement complet de la page + 1s
        window.addEventListener("load", (event) => {
            setTimeout(() => {
                this.toggleShown();
            }, "1000");
        });
    }

    close(){
        this._elem.close.addEventListener('click', (e) => {
            e.preventDefault();
            this.toggleShown();
        });
    }

    toggleShown(){
        this._elem.alerte.classList.toggle('shown');
    }

}

(function () {

    const strateAlerteSelector = document.querySelector('.js-strate-alerte');
    if (strateAlerteSelector){
        const newStrateAlerte = new strateAlerte(strateAlerteSelector);
    }

})();
// ------ MAIL chargé en ajax au clic sur 'nous contacter' -----------
function initSITMailAjax() {
    $('[data-js="mailsit"]').on('click', function (eventObject) {

        if (!$(this).hasClass('loaded')) {
            eventObject.preventDefault();

            let _this = $(this);
            $.ajax({
                url: localizeParams.ajaxurl,
                method: 'POST',
                data: {
                    action: "single_sit_mailto",
                    postid: $(this).attr('data-postid'),
                    lang: localizeParams.currentlanguage
                },
                success: function (data) {
                    result = JSON.parse(data);
                    if (result.success) {
                        _this.attr('href', 'mailto:' + result.data[0]);
                        $('.tooltip', _this).text(result.data[0]);
                        //_this.text(result.data[0]);
                        _this.addClass('loaded');
                        _this.attr('target', '_blank');
                        _this[0].click();
                    }
                },
            });
        }

    })
}

initSITMailAjax();


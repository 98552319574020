// doc : https://www.algolia.com/doc/ui-libraries/autocomplete/core-concepts/templates/#using-components

if (typeof algoliasearch == 'undefined') {
    setTimeout(function () {
        const searchClient = algoliasearch(
            algolia.application_id,
            algolia.search_api_key
        );


        // indexName: 'query_suggestions',//
        const indexName = 'page';

        const predefinedItems = algolia.predefined_items;
        const predefinedItemsPlugin = {
            getSources() {
                return [
                    {
                        sourceId: 'predefinedItemsPlugin',
                        getItems({query}) {
                            //if (!query) {
                            return predefinedItems;
                            //}
                            return predefinedItems.filter((item) =>
                                item.label.toLowerCase().includes(query.toLowerCase())
                            );
                        },
                        getItemUrl({item}) {
                            return item.url;
                        },
                        templates: {
                            item({item, components}) {
                                return components.Highlight({hit: item, attribute: 'label'});
                            },
                        },
                    },
                ];
            },
        };

        // Template personnalisé avec un bouton
        const customItemTemplate = ({item, components, html}) => html`
            <div class="aa-ItemContent">
                <span class="aa-ItemPostTitle">${components.Highlight({hit: item, attribute: 'post_title'})}</span>
                <!-- Utilisation de post_title -->
            </div>
            <div class="aa-ItemActions">
                <button
                        class="aa-ItemActionButton"
                        data-item-title="${item.post_title}"
                >
                    <svg viewBox="0 0 24 24" fill="currentColor">
                        <path d="M8 17v-7.586l8.293 8.293c0.391 0.391 1.024 0.391 1.414 0s0.391-1.024 0-1.414l-8.293-8.293h7.586c0.552 0 1-0.448 1-1s-0.448-1-1-1h-10c-0.552 0-1 0.448-1 1v10c0 0.552 0.448 1 1 1s1-0.448 1-1z"/>
                    </svg>
                </button>
            </div>
        `;


        const querySuggestionsPlugin = createQuerySuggestionsPlugin({
            searchClient,
            indexName: indexName,
            getSearchParams() {
                return {
                    hitsPerPage: 10,
                };
            },
            transformSource({source, onTapAhead}) {
                return {
                    ...source,
                    templates: {
                        ...source.templates,
                        header() {
                            return algolia.suggestion_surtitle;
                        },
                        item(params) {
                            const {item, components, html} = params;
                            // Transformation de l'item avant le rendu
                            item.query = item.post_title; // Modification de la propriété query

                            // Utilisation du template de base
                            return source.templates.item(params);
                        },
                        //item: customItemTemplate
                    },
                };
            },
        });

        const autocompleteSearch = autocomplete({
            container: '#autocomplete',
            placeholder: 'Search',
            //openOnFocus: true,
            insights: true,
            detachedMediaQuery: 'none',
            classNames: {
                input: 'js-search-input'
            },
            plugins: [querySuggestionsPlugin, predefinedItemsPlugin],
        });
    }, 500);
}


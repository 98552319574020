(function () {

    // Selectric est activé par défaut sur toutes les selects,
    // mais nous on ne veut pas l'avoir sur le sélecteur de langues en version tablette / mobile
    $('.js-lang-dropdown-mobile').selectric('destroy');

    $('.js-lang-dropdown-desktop > a').on("click", function (e) {
        e.preventDefault();
        $(this).next().toggleClass("is-open");
    });

    // Select sur la version mobile
    const selectMobile = document.querySelector('.js-lang-dropdown-mobile');
    if (selectMobile) {
        selectMobile.addEventListener('change', (e) => {
            window.location.href = selectMobile.options[selectMobile.selectedIndex].value;
        });
    }


    $('#overlay-menu .sub-menu').each(function(){
        let height = $(this).height();
    })

})();

class menuPrincipal {

    _selector = {
        overlay : '#overlay-menu',
        subMenu : '.sub-menu',
        subMenuElem : '>li', // (à utiliser dans le contexte d'un sub-menu)
        scrollBtn : '.toScroll', // (à utiliser dans le contexte d'un sub-menu)
    }

    constructor(car) {
        let cela = this;
        $(cela._selector.subMenu, cela._selector.overlay).each(function(){
            let height = cela.getHeight(this);
            let childHeight = cela.getContentHeight(this);

            if(childHeight > height){
                $(this).addClass('toScroll');
                cela.listenScroll(this);
                cela.listenClick(this);
            }
        });
    }

    getHeight(target){
        return $(target).height();
    }

    getContentHeight(target){
        let cela = this;
        let totalHeight = 200; //200 de base pour le fondu
        $(cela._selector.subMenuElem, target).each(function(){
            totalHeight += cela.getHeight(this);
        });

        return totalHeight;
    }

    listenScroll(target){
        let cela = this;
        $(target).scroll(function() {
            if($(this).scrollTop() >= 5) {
                cela.disableScrollInstruction(target);
            }else{
                cela.ableScrollInstruction(target);
            }
        });
    }

    listenClick(target){
        $(this._selector.scrollBtn, target).on('click', function(){
            $(target).animate({
                scrollTop: 200
            }, 1000);
        });
    }

    ableScrollInstruction(target){
        $(target).removeClass('hasScrolled');
    }
    disableScrollInstruction(target){
        $(target).addClass('hasScrolled');
    }

}

let newMenuPrincipal = new menuPrincipal();




thWeather.config({
    apiUrl: "/wp-admin/admin-ajax.php",
    soir: '21:00:00',
    nuit: '03:00:00',
});

moment.locale("fr");

function initMeteo() {
    var momentList = ['matin', 'aprem'];
    var day = [0, 1, 2, 3, 4];
    var keep_only_current_moment = false;

// gestion de celui qui est visible directement dans le menu principal
    var topweather = false;

    $('[data-weather]').each(function () {

        var _self = this;

        var city = $(this).data('weather');

        var spe_day = $(this).data('day');
        if (typeof spe_day != 'undefined') {
            day = [spe_day];
        }

        $.each(day, function (k, day) {
            var maxTemp = 0;
            var minTemp = 1000;
            var removeChecker = [];
            var already_res_for_day = false;

            $.each(momentList, function (k, periode) {
                //Données aux différents moments de la journée

                thWeather.get({
                    city: city,
                    moment: periode,
                    day: day,
                    lang: "fr"
                }, function (data) {
                    if (
                        typeof data !== "undefined"
                        && data !== null
                        && data
                        && (!keep_only_current_moment || (keep_only_current_moment && !already_res_for_day))
                    ) {
                        $('#day-' + day + ' .' + periode + ' .temp.default', _self).html(data.degrees + "°C");
                        $('#day-' + day + ' .' + periode + ' .data-icon', _self).html(data.iconHtml);
                        if (!topweather) {
                            thWeather.get({
                                city: city,
                                moment: 'current',
                                day: day,
                                lang: "fr"
                            }, function (datacurrent) {
                                if (typeof datacurrent !== "undefined" && datacurrent !== null && datacurrent) {
                                    $('.data-topweather-weather .data-icon').html(datacurrent.iconHtml);
                                    $('.data-topweather-weather .temp').html(datacurrent.degrees + "°C");
                                    $('.data-topweather-weather .wind').html(parseInt(datacurrent.wind.speed) + "km/h");
                                    $('.data-topweather-weather .wind-direction').html(windirectiontrad[datacurrent.winddirection.direction]);

                                    topweather = true;
                                }
                            });
                        }
                        already_res_for_day = true;
                    } else {
                        //$('#day-' + day + ' .' + periode, _self).html('');
                        removeChecker.push(true);
                    }
                });

                if (removeChecker.length >= 4) {
                    $("#day-" + day, _self).remove();
                }
            });
        });
    });
}

initMeteo();
class compteARebours {

    _selector = {
        parrent : '',
        days : '.d .val',
        hours : '.h .val',
        min : '.m .val',
        sec : '.s .val',
    }

    constructor(car) {
        this._selector.parrent = car;
        const targetTimestamp = parseInt(car.getAttribute('data-target-time'));
        let that = this;

        //Set the loop
        setInterval(function() {
            let currentTimestamp = that.getCurrentTimestamp();
            that.compare(targetTimestamp, currentTimestamp);
        }, 1000);
    }

    getCurrentTimestamp(){
        const currentDate = new Date();
        return currentDate.getTime();
    }

    compare(target, current){
        let compare = (target - current);
        if (compare > 0){
            this.update(compare);
        }
    }

    update(timestamp){
        let days = this.getDays(timestamp);
        let hours = this.getHours(timestamp);
        let min = this.getMin(timestamp);
        let sec = this.getSec(timestamp);

        this._selector.parrent.querySelector(this._selector.days).textContent = days;
        this._selector.parrent.querySelector(this._selector.hours).textContent = hours;
        this._selector.parrent.querySelector(this._selector.min).textContent = min;
        this._selector.parrent.querySelector(this._selector.sec).textContent = sec;
    }

    getDays(timestamp){
        return Math.floor(timestamp / (1000 * 60 * 60 * 24));
    }

    getHours(timestamp){
        return Math.floor((timestamp % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
    }

    getMin(timestamp){
        return Math.floor((timestamp % (1000 * 60 * 60)) / (1000 * 60));
    }

    getSec(timestamp){
        return Math.floor(timestamp % (1000 * 60) / 1000);
    }

}


(function () {
    let compteAReboursArray = [];

    const compteAReboursSelector = document.querySelectorAll('[data-target-time]');
    compteAReboursSelector.forEach(car => {
        let newCompteAR = new compteARebours(car);
        compteAReboursArray.push(newCompteAR);
    });
})();
(function () {

    // Init des sliders
    const sliders = document.querySelectorAll('.js-slider-galerie');
    sliders.forEach(slider => {

        const splide = new Splide(slider, {
            gap: 20,        // gap en JS plutôt que margin en css pour gérer plus facilement l'option 'rtl'
            pagination: false,

            breakpoints: {
                600: {
                    arrows: false,
                },
            }
        }).mount();
    });

})();
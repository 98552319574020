(function () {

    // Init des sliders
    const slidersAncre = document.querySelectorAll('.js-slider-ancres');
    slidersAncre.forEach(function (slider) {
        const splide = new Splide(slider, {
            padding: {left: 40, right: 50},
        }).mount();

        // passe le slider à la lib des ancres si on a besoin de le synchroniser
        th_ancres.selectors.sliderAncre = splide;
    });
})();

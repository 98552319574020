class blocPlanings {
    planingCats = '.js-planing-cat';
    files = '.js-planing';
    context = null;

    constructor(context) {
        this.context = context;
        context.querySelectorAll(this.planingCats).forEach((e) => {
            this.listen(e);
        });
    }

    listen(select){
        const cela = this;
        $(select).selectric().on('change', function(){
            console.log();

            const ids = $(this).val();

            if(ids == "0"){
                cela.context.querySelectorAll(cela.files).forEach((e) => {
                    e.classList.remove('hide');
                });
            }else{
                let idsArray = ids.split(',');
                cela.context.querySelectorAll(cela.files).forEach((e) => {
                    const id = e.getAttribute('data-id');
                    if (idsArray.includes(id)){
                        e.classList.remove('hide');
                    }else{
                        e.classList.add('hide');
                    }
                });
            }
        });
        // select.addEventListener('change', () =>{

        // });
    }

}

const thPlanings = [];
document.querySelectorAll('.js-planings').forEach((context) => {
    thPlanings.push(new blocPlanings(context));
});

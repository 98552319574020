const carteInteractive = {
    selector: {
        form: "#carte_interactive_facettes",
        map: "#carte_interactive_map",
        post_id_attribute: "data-mappostid",
        btnCloseFiltres: "#close_filtres_carte",
        btnOpenFiltres: "#open_filtres_carte"
    },

    map: null,
    markers: [],

    init: function () {
        carteInteractive.carteEvent();
    },

    carteEvent: function () {
        const el = $("#carte_interactive_map");

        if (el.length > 0 && !el.hasClass("parsed")) {
            carteInteractive.loadMap();

            el.addClass("parsed");
        }
    },

    loadMap: function () {
        carteInteractive.map = th_maps.initSingleMap(document.querySelector(carteInteractive.selector.map));

        $.ajax({
            url: "/wp-admin/admin-ajax.php",
            method: "get",
            data: {
                action: "get_carte_interactive",
                pid: document.querySelector(carteInteractive.selector.map).getAttribute(carteInteractive.selector.post_id_attribute)
            }
        })
            .then(function (response) {
                const res = JSON.parse(response);

                carteInteractive.loadPoints(carteInteractive.map, res.data.points);
                carteInteractive.appendHTML(carteInteractive.selector.form, res.data.HTMLInputs);
                th_accordeon.init();

                carteInteractive.inputEvents();
                carteInteractive.openByDefault();
            });
    },

    loadPoints: function (map, json) {
        $.each(json, function () {
            const selection = this.selection_id[0];

            let icon = null;

            if (this.icon) {
                icon = this.icon;

                th_maps.addMarkerIcon(icon, {
                    className: icon,
                    html: '<div class="marker-pin-actif"></div>',
                    iconSize: [30, 30],
                    iconAnchor: [15, 15]
                });
            }

            $.each(this.points, function () {
                const marker = th_maps.createMarker(null, {
                    lat: this.lat,
                    lng: this.lng
                }, icon ? icon : 'default', 'selection-' + selection);
                const lat = this.lat;
                const lng = this.lng;
                const post_id = this.id;

                carteInteractive.markers.push(marker);

                marker.on('click', function () {
                    if (carteInteractive.lastActiveMarker) {
                        if (icon) {
                            carteInteractive.lastActiveMarker.setIcon(th_maps.markersIcons[icon]);
                        } else {
                            carteInteractive.lastActiveMarker.setIcon(th_maps.markersIcons['default']);
                        }
                        carteInteractive.lastActiveMarker = null;
                    }
                    marker.setIcon(th_maps.markersIcons['hover']);
                    carteInteractive.lastActiveMarker = marker;

                    $.ajax({
                        url: "/wp-admin/admin-ajax.php",
                        method: "POST",
                        data: {
                            action: "get_carte_interactive_point",
                            post_id: post_id
                        },
                        success: function (html) {
                            const response = JSON.parse(html);
                            const content = response.html;

                            const infoWindow = th_maps.createInfoWindow(content);
                            infoWindow.on('remove', function () {
                                if (icon) {
                                    carteInteractive.lastActiveMarker.setIcon(th_maps.markersIcons[icon]);
                                } else {
                                    carteInteractive.lastActiveMarker.setIcon(th_maps.markersIcons['default']);
                                }
                            });
                            infoWindow.setLatLng({lat: lat, lng: lng}).openOn(map);

                            const lazyLoadInstance = new LazyLoad({
                                elements_selector: ".lazy"
                            });

                            th_favoris.parseItems();
                            th_favoris.addEvents();
                        }
                    });
                })

            })
        });

        const group = new L.featureGroup(carteInteractive.markers);
        carteInteractive.map.fitBounds(group.getBounds(), {padding: [50, 50]});

        $(carteInteractive.selector.form).on('submit', function (e) {
            e.preventDefault();
            th_overlay.close('overlay-filtres-carte');
        });
    },

    appendHTML: function (container, content) {
        if (container && content) {
            document.querySelector(container).innerHTML = content;
        }
    },


    inputEvents: function () {
        $("input[type='checkbox']", $(carteInteractive.selector.form)).on("change", function () {
            const el = this;
            setTimeout(function () {
                if (el.checked) {
                    th_maps.showGroupMarker('selection-' + el.value, carteInteractive.map);
                } else {
                    th_maps.hideGroupMarker('selection-' + el.value);
                }
            }, 50);
        });

        $("input[type='checkbox']", $(carteInteractive.selector.form)).on("force-change", function () {
            const el = this;
            setTimeout(function () {
                if (el.checked) {
                    th_maps.showGroupMarker('selection-' + el.value, carteInteractive.map);
                } else {
                    th_maps.hideGroupMarker('selection-' + el.value);
                }
            }, 50);
        });
    },

    openByDefault: function () {
        $("input[type='checkbox']", $(carteInteractive.selector.form)).each(function () {
            if (this.getAttribute("data-display-default")) {
                $(this).click();
            }
        });
    }
};

carteInteractive.init();